import React from "react"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import styled from "styled-components"

import Section from "../components/Section"
import {
  Container,
  ContainerSplit,
  Split,
  SplitContent,
} from "../components/Container"
import LeftSideHero from "../components/LeftSideHero"

import { LinkUnderline } from "../components/NormalLink"
import SEO from "../components/seo"
import breakpoints from "../styles/breakpoints"
import vSpacing from "../styles/vSpacing"
import { AfricaOutlineBackground, Location } from "../styles/sharedStyles";
import Vspacer from "../components/Vspacer"
import HeroFullWidth from "../components/HeroFullWidth"
import SvgBorderTop from "../components/SvgBorderTop"
import BlockText from "../components/BlockText"
import ContactCard from "../components/ContactCard"
import UspWithIllustration from "../components/UspWithIllustration"
import FaqCategory from "../components/FaqCategory"
import BreadCrumbs from "../components/BreadCrumbs"
import slugify from "../utils/slugify"
import { currentLocale } from "../locale/languages"

const Title2 = styled.h2`
  color: ${(props) => props.theme.primaryColors.primary};
  padding-bottom: ${vSpacing.baseX4};
  text-align: center;
  ${breakpoints.md`
    text-align: left;
  `};
`

const Title3 = styled.h2`
  color: ${(props) => props.theme.primaryColors.primary};
  padding-bottom: ${vSpacing.baseX4};
  text-align: center;
  ${breakpoints.md`
    text-align: left;
  `};
`

const Title4 = styled.h2`
  color: ${(props) => props.theme.primaryColors.primary};
  padding-bottom: ${vSpacing.baseX3};
  text-align: center;
  ${breakpoints.md`
    text-align: left;
  `};
`

export const query = graphql`
  {
    sanityAboutUsPage {
      _rawLocaleTitle
      image {
        asset {
          gatsbyImageData(width: 1600 fit: FILLMAX placeholder: BLURRED)
        }
      }
      _rawTextBlock1Title
      _rawTextBlock1
      _rawTextBlock2Title
      _rawTextBlock2
      _rawTextBlock3
      imageTextBlock {
        asset {
          gatsbyImageData(width: 960 fit: FILLMAX placeholder: BLURRED)
        }
      }
      _rawLocationsTitle
      _rawLocationsTextBlock
      _rawTeamTitle
      _rawTeamTextBlock
      faqCategory {
        _rawFaqs
      }
    }
    allSanityTeam {
      edges {
        node {
          id
          name
          degree
          phone
          email
          image {
            asset {
              url
            }
          }
        }
      }
    }
    allSanityVacancyCountry {
      edges {
        node {
          id
          _rawLocaleTitle
          flag {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    _rawLocaleTitle,
    image,
    _rawTextBlock1Title,
    _rawTextBlock1,
    _rawTextBlock2Title,
    _rawTextBlock2,
    _rawTextBlock3,
    imageTextBlock,
    _rawLocationsTitle,
    _rawLocationsTextBlock,
    _rawTeamTitle,
    _rawTeamTextBlock,
    faqCategory,
  } = data.sanityAboutUsPage

  const { allSanityTeam } = data
  const { allSanityVacancyCountry } = data
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {_rawLocaleTitle && (
        <BreadCrumbs
          color="white"
          pageTitle={_rawLocaleTitle[locale]}
          locale={locale}
        />
      )}
      {image && (
        <HeroFullWidth
          img={image}
          title={_rawLocaleTitle[locale]}
        />
      )}
      {_rawTextBlock1 && (
        <Container paddingTop paddingBottom>
          <Section>
            <Grid container>
              <Grid item xs={false} md={2} />
              <Grid item xs={12} md={6}>
                {_rawTextBlock1Title && (
                  <Title2>{_rawTextBlock1Title[locale]}</Title2>
                )}
                <BlockText blocks={_rawTextBlock1[locale]} />
              </Grid>
            </Grid>
          </Section>
        </Container>
      )}
      {_rawTextBlock2 && imageTextBlock && (
        <ContainerSplit paddingBottom>
          <Split>
            <LeftSideHero img={imageTextBlock} />
          </Split>
          <Split fixedWidth="608px">
            <SplitContent>
              {_rawTextBlock2Title && (
                <Title3>{_rawTextBlock2Title[locale]}</Title3>
              )}
              <BlockText blocks={_rawTextBlock2[locale]} />
            </SplitContent>
          </Split>
        </ContainerSplit>
      )}

      {_rawTextBlock3 && (
        <Container paddingBottom>
          <Section>
            <Grid container>
              <Grid item xs={12} md={12}>
                <BlockText blocks={_rawTextBlock3[locale]} />
              </Grid>
            </Grid>
          </Section>
        </Container>
      )}

      <Section paddingBottom>
        <UspWithIllustration locale={locale} />
      </Section>

      <SvgBorderTop grey>
        <Container grey paddingBottom paddingTop>
          <AfricaOutlineBackground>
            <Section>
              {_rawLocationsTextBlock && (
                <>
                  <Vspacer marginBottom={vSpacing.baseX7}>
                    <Grid container>
                      <Grid item xs={false} md={1} />
                      <Grid item xs={12} md={6}>
                        {_rawLocationsTitle && (
                          <Title4>{_rawLocationsTitle[locale]}</Title4>
                        )}
                        <BlockText blocks={_rawLocationsTextBlock[locale]} />
                      </Grid>
                    </Grid>
                  </Vspacer>
                  {allSanityVacancyCountry.edges && (
                    <Vspacer marginBottom={vSpacing.baseX15}>
                      <Grid item xs={false} md={1} />
                      <Grid container item xs={12} md={6}>
                        {allSanityVacancyCountry.edges.map((country) => (
                          <Grid item xs={12} md={6} key={country.node.id}>
                            <Vspacer marginBottom={vSpacing.baseX2}>
                              <LinkUnderline
                                to={`/${setLocaleInUrl}${slugify(
                                  country.node._rawLocaleTitle[locale]
                                )}`}
                              >
                                <Location>
                                  {country.node.flag && (
                                    <img
                                      src={`${country.node.flag.asset.url}?w=24`}
                                      style={{
                                        marginRight: "12px",
                                        maxWidth: "24px",
                                      }}
                                      alt={
                                        country.node._rawLocaleTitle[locale] ||
                                        "Country"
                                      }
                                    />
                                  )}
                                  <p>{country.node._rawLocaleTitle[locale]}</p>
                                </Location>
                              </LinkUnderline>
                            </Vspacer>
                          </Grid>
                        ))}
                      </Grid>
                    </Vspacer>
                  )}
                </>
              )}
              {_rawTeamTextBlock && (
                <Vspacer marginBottom={vSpacing.baseX7}>
                  <Grid container>
                    <Grid item xs={false} md={1} />
                    <Grid item xs={12} md={6}>
                      <Title4>{_rawTeamTitle[locale]}</Title4>
                      <BlockText blocks={_rawTeamTextBlock[locale]} />
                    </Grid>
                  </Grid>
                </Vspacer>
              )}
              {allSanityTeam.edges && (
                <Grid container spacing={16} justify="center">
                  {allSanityTeam.edges.map((contact) => (
                    <Grid item xs={12} md={6} key={contact.node.id}>
                      <ContactCard contact={contact.node} locale={locale} />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Section>
          </AfricaOutlineBackground>
        </Container>
      </SvgBorderTop>
      {faqCategory && (
        <SvgBorderTop>
          <Container paddingTop paddingBottom>
            <Section>
              <FaqCategory category={faqCategory} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
    </>
  )
}

export default IndexPage
