import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import svgBorderLeftHero from "./svgBorder"
import breakpoints from "../../styles/breakpoints"

const ImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  min-height: 529px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 38px;
    transform: rotate(180deg);
    width: 100%;
    background: url("data:image/svg+xml,%3csvg width='320' height='57' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 57V23.021c2.1872-.6288 4.6982-1.4944 6.8843-2.4181 2.6457-1.0997 6.0011-2.0994 7.4207-2.0994s7.098-1.7994 12.5183-3.8987c5.4204-2.0993 15.8093-3.299 22.9073-2.4992 7.098.7998 14.0025 1.1996 15.2285.7998 1.226-.3 10.8406-.4999 21.2941-.3 27.8114.4999 27.7468.4999 40.007-.8996 16.0029-1.7995 22.3911-1.7995 25.2303.1 1.8713 1.2995 4.0653 1.5994 10.647 1.2995 4.5815-.2 9.163-.8997 10.1309-1.4995 1.2905-.7998 6.969-.9997 17.616-.6998 9.0339.3 16.519.1 17.5515-.4998.968-.4999 4.3879-1.0997 7.5497-1.3996 8.3886-.5998 11.5504-1.1996 17.616-3.3989 3.549-1.2995 6.5818-1.6994 8.8403-1.3995 2.323.3999 4.4524 0 6.84-1.2996 3.0327-1.4995 5.4848-1.7994 18.0031-1.5995 8.0014.1 16.261-.2999 18.3258-.7997 3.1619-.7998 4.0653-.6998 4.9686.5998.8389 1.0996 2.5811 1.4995 7.3562 1.4995 4.0007 0 6.9044.3999 8.0014 1.2996.9034.7997 3.7426 1.6994 6.1946 1.9993 1.6087.1917 5.0368.6694 8.8684 1.198V57H0z' fill='${(
        props
      ) =>
        props.grey
          ? "%23f6f6f4"
          : "%23ffffff"}' fill-rule='evenodd'/%3e%3c/svg%3e")
      top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 899;
    ${breakpoints.md`
      transform: none;
      background: none;
      border: 70px solid;
      border-image-source:url("${svgBorderLeftHero}");
      border-image-slice: 180;
      height: 100%;
      min-height: 529px;
  `};
  }
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 38px;
    margin-top: -38px;
    width: 100%;
    background: url("data:image/svg+xml,%3csvg width='320' height='57' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 57V23.021c2.1872-.6288 4.6982-1.4944 6.8843-2.4181 2.6457-1.0997 6.0011-2.0994 7.4207-2.0994s7.098-1.7994 12.5183-3.8987c5.4204-2.0993 15.8093-3.299 22.9073-2.4992 7.098.7998 14.0025 1.1996 15.2285.7998 1.226-.3 10.8406-.4999 21.2941-.3 27.8114.4999 27.7468.4999 40.007-.8996 16.0029-1.7995 22.3911-1.7995 25.2303.1 1.8713 1.2995 4.0653 1.5994 10.647 1.2995 4.5815-.2 9.163-.8997 10.1309-1.4995 1.2905-.7998 6.969-.9997 17.616-.6998 9.0339.3 16.519.1 17.5515-.4998.968-.4999 4.3879-1.0997 7.5497-1.3996 8.3886-.5998 11.5504-1.1996 17.616-3.3989 3.549-1.2995 6.5818-1.6994 8.8403-1.3995 2.323.3999 4.4524 0 6.84-1.2996 3.0327-1.4995 5.4848-1.7994 18.0031-1.5995 8.0014.1 16.261-.2999 18.3258-.7997 3.1619-.7998 4.0653-.6998 4.9686.5998.8389 1.0996 2.5811 1.4995 7.3562 1.4995 4.0007 0 6.9044.3999 8.0014 1.2996.9034.7997 3.7426 1.6994 6.1946 1.9993 1.6087.1917 5.0368.6694 8.8684 1.198V57H0z' fill='${(
        props
      ) =>
        props.grey
          ? "%23f6f6f4"
          : "%23ffffff"}' fill-rule='evenodd'/%3e%3c/svg%3e")
      top center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 899;
    ${breakpoints.md`
      background: none;
  `};
  }
`

const LeftSideHero = ({ img }) => {
  const image = getImage(img.asset);

  const HeroImg = (
    <ImgWrapper>
      <GatsbyImage
        image={image}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
        alt="image"
      />
    </ImgWrapper>
  )
  return <React.Fragment>{img ? HeroImg : null}</React.Fragment>
}

export default LeftSideHero
